<template>
  <div class="row">
    <div class="col-12">
      <form @submit.prevent="getOrgs">
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-addon1">
            <svg 
              width="1em"
              height="1em"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round">
              <use href="@/assets/img/feather-sprite.svg#search"/>
            </svg>
          </span>
          <input type="hidden" name="canary" value="coalmine" />
          <input type="text" class="form-control" v-model="searchQuery" placeholder="Search by Job Title" aria-label="Search by Job Title" aria-describedby="button-search" :disabled="searching">
          <button class="btn btn-secondary px-4" type="submit" id="button-search" :disabled="searching">Search</button>
        </div>
      </form>
    </div>
  </div>
  <div class="row mb-4 mb-sm-5 px-2">
    <div class="col-auto p-1">
      <button class="btn btn-yellow btn-sm fw-semibold px-3" v-on:click="getOrgs('UX Designer')">UX Designer</button>
    </div>
    <div class="col-auto p-1">
      <button class="btn btn-yellow btn-sm fw-semibold px-3" v-on:click="getOrgs('Software Engineer')">Software Engineer</button>
    </div>
    <div class="col-auto p-1">
      <button class="btn btn-yellow btn-sm fw-semibold px-3" v-on:click="getOrgs('Product Manager')">Product Manager</button>
    </div> 
    <div class="col-auto p-1">
      <button class="btn btn-yellow btn-sm fw-semibold px-3" v-on:click="getOrgs('Project Manager')">Project Manager</button>
    </div> 
    <div class="col-auto p-1">
      <button class="btn btn-yellow btn-sm fw-semibold px-3" v-on:click="getOrgs('Nurse')">Nurse</button>
    </div>
    <div class="col-auto p-1">
      <button class="btn btn-yellow btn-sm fw-semibold px-3" v-on:click="getOrgs('Store Manager')">Store Manager</button>
    </div>
    <div class="col-auto p-1">
      <button class="btn btn-yellow btn-sm fw-semibold px-3" v-on:click="getOrgs('Engineer')">Engineer</button>
    </div>
    <div class="col-auto p-1">
      <button class="btn btn-yellow btn-sm fw-semibold px-3" v-on:click="getOrgs('Admin')">Admin</button>
    </div>
    <div class="col-auto p-1">
      <button class="btn btn-yellow btn-sm fw-semibold px-3" v-on:click="getOrgs('IT')">IT</button>
    </div>
    <div class="col-auto p-1">
      <button class="btn btn-yellow btn-sm fw-semibold px-3" v-on:click="getOrgs('Sales')">Sales</button>
    </div>
    <div class="col-auto p-1">
      <button class="btn btn-yellow btn-sm fw-semibold px-3" v-on:click="getOrgs('Business Development')">Business Development</button>
    </div>
    <div class="col-auto p-1">
      <button class="btn btn-yellow btn-sm fw-semibold px-3" v-on:click="getOrgs('Consultant')">Consultant</button>
    </div>
    <div class="col-auto p-1">
      <button class="btn btn-yellow btn-sm fw-semibold px-3" v-on:click="getOrgs('Customer Service')">Customer Service</button>
    </div>
    <div class="col-auto p-1">
      <button class="btn btn-yellow btn-sm fw-semibold px-3" v-on:click="getOrgs('Operations')">Operations</button>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4" v-for="org in orgs" :key="org.diffbotId">
      <div class="card h-100 d-flex flex-wrap align-content-between">
        <div class="card-folder-header bg-tan text-start">
          <div class="mx-3 mt-3" v-if="searchedAll === false">
            <h6 class="fw-bold text-uppercase small mb-1">MATCHED ROLES</h6>
            <ul class="list-unstyled small">
              <li v-for="title in org.titles.slice(0,3)" :key="title">
                <a :href="`${org.indeedUri}?q=${title}`" target="_blank">
                  {{ title }}
                </a>
              </li>
              <li v-if="org.titles.length > 3">... and {{ org.titles.length - 3 }} more.</li>
            </ul>
          </div>
          <div class="card-body p-4">
            <div class="row">
              <div class="col-5 mb-2">
                <div class="org-avatar">
                  <img :src="org.entity.logo" class="img-fluid org-avatar-img" />
                </div>
              </div>
            </div>
            <div class="row text-start">
              <div class="col-12 mb-2">
                <h3 class="fw-bold">{{ org.entity.name }}</h3>
              </div>
              <div class="col-12 mb-2">
                <p class="small">
                  <span class="badge bg-yellow fw-xbold me-1 text-dark">100/100</span> Corporate Equality Index
                </p>
                <p class="small" v-if="org.entity.summary.length > 20">
                  {{ org.entity.summary }}
                </p>
                <p class="small" v-else-if="org.entity.description">
                  {{ firstSentence(org.entity.description) }}
                </p>
                <p class="small mb-2">
                  <svg class="feather me-1">
                    <use href="@/assets/img/feather-sprite.svg#user"/>
                  </svg>
                  {{ org.entity.nbEmployees }}+ Employees
                </p>
                <p v-if="org.entity.location" class="small">
                  <svg class="feather me-1">
                    <use href="@/assets/img/feather-sprite.svg#map-pin"/>
                  </svg>
                  <span v-if="org.entity.location.city">{{ org.entity.location.city.name }}</span>
                  <span v-if="org.entity.location.region">, {{ org.entity.location.region.name }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-auto m-4">
          <div class="d-grid">
            <a class="btn btn-primary fw-semibold" type="button" :href="`${org.indeedUri}/?q=${searchQuery}`" target="_blank" rel="noopener">View Jobs</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

// Enables Fetching of URL Parameters Directly
function queryString(name) {
    name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export default {
  name: 'Jobs-List',
  data: () => ({
      orgs: [],
      searchQuery: 'UX Designer',
      searching: false,
      searchedAll: false
  }),
  mounted() {
    let search = queryString('q') ? queryString('q') : 'UX Designer';
    this.getOrgs(search);
  },
  methods: {
    getOrgs: function(query) {
      // Support direct calls
      if (typeof query === 'string') {
        this.searchQuery = query;
      }
      // Search state for UX
      this.searching = true;

      // Set Query Params for Search
      var currentURL = window.location.protocol + "//" + window.location.host + window.location.pathname + '?q=' + encodeURIComponent(this.searchQuery);
      window.history.pushState({ path: currentURL }, '', currentURL);

      // console.log(`${process.env.VUE_APP_PRIDEAPPSERVER}/getJobs?title=${encodeURIComponent(this.searchQuery)}`);
      return axios.get(`${process.env.VUE_APP_PRIDEAPPSERVER}/getJobs?title=${encodeURIComponent(this.searchQuery)}`)
        .then((response) => {
          this.searching = false;
          this.orgs = response.data;
          if (this.searchQuery === "") {
            this.searchedAll = true;
          }
          else {
            this.searchedAll = false;
          }
        })
    },
    // Used to extract just the first sentence of a long org description
    firstSentence: function(desc) {
      if (desc && desc.length > 0) {
        let firstSentence = desc.match(/^(.*?)[.?!]\s/);
        return firstSentence && firstSentence[1] ? firstSentence[1] : desc;
      }
      else{
        return desc;
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
