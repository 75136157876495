<template>
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container mt-3 mb-5">
      <div class="navbar-brand">
        <img src="@/assets/logomark.svg" alt="Diffbot Logomark" />
      </div>
      <div class="d-flex">
        <a href="#" data-bs-toggle="modal" data-bs-target="#whyModal">Why?</a>
      </div>
    </div>
  </nav>
  <div class="container mb-5">
    <div class="row justify-content-center mb-3 mb-sm-5">
      <div class="col-12 col-md-8">
        <h1 class="display-4 fw-xbold text-uppercase mb-4 mb-sm-3">842 LGBTQ+ Friendly <br />Companies to Work For</h1>
        <p>
          Rated & evaluated by the <a href="https://www.hrc.org/resources/best-places-to-work-for-lgbtq-equality-2022" target="_blank" rel="noopener">Human Rights Campaign</a>. Enhanced by <a href="https://www.diffbot.com" target="_blank">Diffbot</a>.
        </p>
      </div>
    </div>
    <Jobs />
  </div>
<!-- Modal -->
<div class="modal fade" id="whyModal" tabindex="-1" aria-labelledby="whyModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header border-0">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body text-center mx-3">
        <h2 class="fw-xbold text-uppercase mb-3">Another Job Board?</h2>
        <p>
          <strong>Yes, and also no.</strong> Job seeking is a full-time job made extra stressful for those who feel they can't bring their whole selves to interviews and work.
        </p>
        <p>
          With the 842 companies on the <a href="https://www.hrc.org/resources/best-places-to-work-for-lgbtq-equality-2022" target="_blank" rel="noopener">Corporate Equality Index</a>, you absolutely can.
        </p>
        <p>
          Except instead of searching through a list of company names, you can search this site to find LGBTQ+ friendly companies by role, hiring today.
        </p>
        <p>
          It's not quite a job board, but hopefully useful enough to find your next move at a place that celebrates you for you. 
        </p>
        <p>
          100% free and anonymous forever. Brought to you by <a href="https://www.diffbot.com">Diffbot</a>.
        </p>
        <div class="input-group my-4 mb-5 justify-content-center">
          <button type="button" class="btn btn-success ps-4 pe-3" v-on:click="copyUrl">
            <svg class="feather me-1">
              <use href="@/assets/img/feather-sprite.svg#copy" fill="none" stroke="#FFF" />
            </svg>
            {{ shareCopy }}
          </button>
          <button type="button" class="btn btn-success dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
            <span class="visually-hidden">Toggle Dropdown</span>
          </button>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="http://twitter.com/share?&url=pride.diffbot.com">
              <svg class="feather me-1">
                <use href="@/assets/img/feather-sprite.svg#twitter" fill="none" />
              </svg>
              Tweet on Twitter
            </a></li>
            <li><a class="dropdown-item" href="https://www.linkedin.com/sharing/share-offsite/?url=pride.diffbot.com">
              <svg class="feather me-1">
                <use href="@/assets/img/feather-sprite.svg#linkedin" />
              </svg>
              Post on LinkedIn
            </a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Jobs from './components/Jobs.vue'

export default {
  name: 'App',
  title: "842 LGBTQ+ Friendly Companies To Work For",
  components: {
    Jobs
  },
  data: () => ({
    shareCopy: "Share with a Friend"
  }),
  methods: {
    copyUrl: function() {
      navigator.clipboard.writeText("https://pride.diffbot.com");
      this.shareCopy = "Copied! Share away!"
      setTimeout(() => this.shareCopy = "Share with a Friend", 1000)
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;700;800&display=swap');

#app {
  font-family: Barlow, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

</style>
